import React, { useMemo, useContext, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { withFirebase } from '../services/firebase'
import { FirebaseContext } from '../context/FirebaseContext'
import { notificationsStore } from './ToastNotifications'
import './CardForm.css'
import axios from 'axios'

import useResponsiveFontSize from "../useResponsiveFontSize";

 // Check if we have a zero decimal currency
    // https://stripe.com/docs/currencies#zero-decimal
    function zeroDecimalCurrency(amount, currency) {
      let numberFormat = new Intl.NumberFormat(['en-US'], {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'symbol',
      });
      const parts = numberFormat.formatToParts(amount);
      let zeroDecimalCurrency = true;
      for (let part of parts) {
        if (part.type === 'decimal') {
          zeroDecimalCurrency = false;
        }
      }
      return zeroDecimalCurrency;
    }
     // Format amount for Stripe
    function formatAmountForStripe(amount: number, currency: 'GBP' | 'USD' | 'EUR' | 'JPY') {
        return zeroDecimalCurrency(amount, currency)
        ? amount
        : Math.round(amount * 100);
    }

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const CardForm = ({closePaymentModal, firebase}) => {
  const [paymentObj, setPaymentObj] = useState({});
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const FIREBASE_URL = 'https://us-central1-random-variations-api-staging.cloudfunctions.net/charge';
  const user = firebase.auth().currentUser
  const handleSubmit = async event => {
    event.preventDefault()
    if (!stripe || !elements) {
      return;
    }
    axios.post(FIREBASE_URL, { currency: 'usd',  amount: formatAmountForStripe(9.99, 'USD'), description: 'single time cost'})
        .then((res) => {
          const cs = res.data.body.charge.client_secret;
          stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
              address: {
                city: "Los Angeles",
                country: "US",
                postal_code: "91205",
                state: "MI"
              },
              email: user.email,
              name: user.displayName,
              phone: "123-456-7889"
            },
          })
          .then((data) => {
            setPaymentObj(data);
            stripe.confirmCardPayment(cs, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  address: {
                    city: "Los Angeles",
                    country: "US",
                    postal_code: "91205",
                    state: "MI"
                  },
                  email: user.email,
                  name: user.displayName,
                  phone: "123-456-7889"
                },
              }
            }).then((response) => {
              if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
                firebase.firestore().collection('stripe_customers').doc(user.uid).collection('payments').add(data.paymentMethod)
                firebase.firestore().collection('user_payment').doc(user.uid).set({isPaid:true})
                notificationsStore.showNotification({
                  message: `Payment has been completed successfully`,
                  level: 'success',
                  autohide: 15000,
                })
                closePaymentModal()
              }
            })
            .catch((err) => {
              notificationsStore.showNotification({
                message: `Payment has not been completed, Please try again`,
                level: 'warning',
                autohide: 15000,
              })
            });
          })
          .catch((err) => {
            notificationsStore.showNotification({
              message: `Payment has not been completed, Please try again`,
              level: 'warning',
              autohide: 15000,
            })
          });
        })
        .catch((err) => {
          notificationsStore.showNotification({
            message: `Payment has not been completed, Please try again`,
            level: 'warning',
            autohide: 15000,
          })
        })

    
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Card details
        <CardElement
          options={options}
          onReady={() => {
            console.log("CardElement [ready]");
          }}
          onChange={event => {
            console.log("CardElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardElement [blur]");
          }}
          onFocus={() => {
            console.log("CardElement [focus]");
          }}
        />
      </label>
      <button type="submit" disabled={!stripe}>
        Pay $9.99
      </button>
    </form>
  );
};

export default withFirebase(CardForm);
