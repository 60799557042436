import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { css, cx } from 'react-emotion'
import {withStyles} from '@material-ui/core'
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SignInModal from './modals/SignInModal'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://random-variations.app/">
        Random Variations
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

type SignInProps = {
    firebase: any
  }
  
  type SignInState = {
    isSignedIn: boolean,
    signInModalIsOpen: boolean
  }

class SignIn extends React.Component<SignInProps , SignInState> {

    constructor(props) {
        super(props)
        this.state = {
            isSignedIn: false,
            signInModalIsOpen: false
        }
    }

    private closeSignInModal = () => this.setState({ signInModalIsOpen: false })
  private openSignInModal = () => this.setState({ signInModalIsOpen: true })
  render() {
    return (
        <Container component="main" maxWidth="xs" style={{height: '100vh', display: 'flex',justifyContent:'center',alignItems:'center'}}>
          <div>
      <CssBaseline />
      {/* <div className={css(`marginTop: 64px; display: flex; flexDirection: column; alignItems: center`)}> */}
        <Avatar className={css(`margin: 4px; backgroundColor: theme.palette.secondary.main`)}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <SignInModal
            isOpen={this.state.signInModalIsOpen}
            onClose={this.closeSignInModal}
          />
        <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={this.openSignInModal}
          >
            Sign In
          </Button>
      {/* </div> */}
      <Box mt={8}>
        <Copyright />
      </Box>
      </div>
    </Container>
    )
  }
}

export default SignIn;
