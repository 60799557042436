import * as React from 'react'
import * as firebase from 'firebase'
import {Dialog,DialogContent, DialogTitle } from '@material-ui/core/'
import withMobileDialog, {
  InjectedProps,
} from '@material-ui/core/withMobileDialog'
import CardForm from '../CardForm'
import { withFirebase } from '../../services/firebase'

type PaymentModalProps = {
  firebase: any
}

type PaymentModalState = {
    isPaymentModalOpen: boolean,
}


class PaymentModal extends React.Component<PaymentModalProps & InjectedProps, PaymentModalState> {

    constructor(props) {
        super(props)
        this.state = {
            isPaymentModalOpen: true
        }
    }

  private closePaymentModal = () => this.setState({ isPaymentModalOpen: false })


  render() {
    return (
    <Dialog
            open={this.state.isPaymentModalOpen}
            fullScreen={false}
            onClose={this.closePaymentModal}
            maxWidth="lg"
            aria-labelledby="sign-in-dialog"
        >
          <DialogTitle id="sign-in-dialog">Stripe</DialogTitle>
            <DialogContent>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', width: '800px' }}>
                <CardForm closePaymentModal={this.closePaymentModal}/>
                 </div>
            </DialogContent>
        
      </Dialog>
    )
  }
}

export default withFirebase(withMobileDialog<PaymentModalProps>()(PaymentModal))
