import * as React from 'react'
import { hot } from 'react-hot-loader'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import App from './App'
import SignIn from './SignIn'
import FirebaseProvider from './providers/FirebaseProvider'

// `loadStripe` should be called outside of a component's render to avoid recreating the `Stripe` object on every render
const stripePromise = loadStripe('pk_test_51H3aFAKmdFXDuvkaKskkvliDkh0pObsoAOsv5Mg9rjBg7uA8y2rt07EMPjBDOknfkLq3pMxiJ2Ggsz7wX9JjKq9M00Qol2gyDA')

class Root extends React.Component {
  render() {
    return (
      <Elements stripe={stripePromise}>
        <FirebaseProvider>
          <Router>
            <Switch>
              <Route path="/sign-in" component={SignIn} />
              <Route path="/" component={App} />
              <Route path="/s/:sessionKey?" component={App} />
              <Route path="/shared/:sharedSessionKey" component={App} />
            </Switch>
          </Router>
        </FirebaseProvider>
      </Elements>
    )
  }
}

export default hot(module)(Root)
