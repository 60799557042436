import React, { useState } from 'react'
import { FirebaseContext } from '../../context/FirebaseContext'

const FirebaseProvider = (props) => {
    const currentUser = {}
    const setCurrentUser = (firebaseUser) => {
        setState({ ...state, currentUser: firebaseUser })
    }
    const initialState = {
        currentUser,
        setCurrentUser
    }
    const [state, setState] = useState(initialState)

    return (
        <FirebaseContext.Provider value={state}>
            {props.children}
        </FirebaseContext.Provider>
    )
}

export default FirebaseProvider